.lia-node-topics-count {
  display: inline-flex;
  align-items: center;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.lia-topics-count-text {
  font-weight: var(--lia-font-weight-md);
  font-size: var(--lia-bs-font-size-sm);
  color: var(--lia-bs-text-muted);
}
